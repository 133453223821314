<template>
    <div>
        <v-container color="primary">
            <v-card
            icon="clipboard-text"
            title="Subscription requests"
            class="px-5 py-10"
            >
            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details>
                </v-text-field>
            </v-card-title>
                <v-data-table
                dense
                :headers="fields"
                :items="items"
                :search="search"
                class="elevation-1"
                ref="usersList">
                <template  v-slot:[`header.legalName`]="{ header }">
                    <h6><b> {{ header.text}}</b> </h6>
                </template>
                <template  v-slot:[`header.commercialName`]="{ header }">
                    <h6><b> {{ header.text}}</b> </h6>
                </template>
                 <template  v-slot:[`header.legalStructure`]="{ header }">
                    <h6><b> {{ header.text}}</b> </h6>
                </template>
                 <template  v-slot:[`header.yearOfEstablishment`]="{ header }">
                    <h6><b> {{ header.text}}</b> </h6>
                </template>
                <template  v-slot:[`header.webSite`]="{ header }">
                    <h6><b> {{ header.text}}</b> </h6>
                </template>
                <template  v-slot:[`header.industryType`]="{ header }">
                     <h6><b> {{ header.text}}</b> </h6>
                </template>
                <template  v-slot:[`header.businessSector`]="{ header }">
                     <h6><b> {{ header.text}}</b> </h6>
                </template>
                 <template  v-slot:[`header.businessSubSector`]="{ header }">
                     <h6><b> {{ header.text}}</b> </h6>
                </template>
                 <template  v-slot:[`header.contactPerson`]="{ header }">
                    <h6><b> {{ header.text}}</b> </h6>
                </template>
                 <template  v-slot:[`header.contactPersonMail`]="{ header }">
                    <h6><b> {{ header.text}}</b> </h6>
                </template>
                 <template  v-slot:[`header.contactPersonMobile`]="{ header }">
                     <h6><b> {{ header.text}}</b> </h6>
                </template>
                 <template  v-slot:[`header.package`]="{ header }">
                     <h6><b> {{ header.text}}</b> </h6>
                </template>
                 <template  v-slot:[`header.subscriptionPeriod`]="{ header }">
                    <h6><b> {{ header.text}}</b> </h6>
                </template>
                 <template  v-slot:[`header.Action`]="{ header }">
                     <h6><b> {{ header.text}}</b> </h6>
                </template>
                    <template v-slot:[`item.Action`]="{ item }">
                            <!-- <v-icon small class="mr-2" color="red darken-2" @click="removeRequest(item,item.index)">X</v-icon>
                            <v-icon small class="mr-2" color="green darken-2" >mdi-correct</v-icon> -->
                            <v-row
                                align="center"
                                justify="center">
                                 <v-col cols="12">
                                     <v-btn-toggle
                                        mandatory>
                                            <v-btn @click="removeRequest(item)" color="error" small>Delete</v-btn>
                                            <v-btn @click="approveRequestConfirm(item)" color="green" small>Approve</v-btn>
                                     </v-btn-toggle>

                                 </v-col>
                            </v-row>

                    </template>
                </v-data-table>
            </v-card>
        </v-container>
         <v-dialog v-model="deleteReqDialog" max-width="500px">
          <v-card>
            <v-card-title class="text-h3">Are you sure you want to delete request </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="deleteRequest">OK</v-btn>
              <v-btn color="green darken-1" text @click="closeDelete">cancel</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
         <v-dialog v-model="approveReqDialog" max-width="500px">
          <v-card
          :loading = loading
    loading-text="Loading... Please wait">
            <v-card-title class="text-h3">ِApproval will create new subscription, proceed ?  </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="approveRequest" :disabled="loading">Yes</v-btn>
              <v-btn color="green darken-1" text @click="closeApprove">No</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="text-center">
         <v-snackbar
        v-model="snackbar"
        timeout="1000">
            {{ alertMsg }}
            <template v-slot:action="{ attrs }">
                <v-btn
                color="green"
                text
                v-bind="attrs"
                @click="snackbar = false">
                Close
                </v-btn>
            </template>
         </v-snackbar>
    </div>

         <!-- <b-table
        striped hover
        :items="items"
        :fields="fields2"
        ref="usersList"
        v-if="items.length > 0">
            <template #cell(Action)="row">
                 <b-container>
                     <b-row class="my-1">
                          <b-col sm="6">
                               <b-button  squared  title="Delete" @click="RemoveRegisteration(row.item,row.index)">
                                    {{ $t("registerationManage.remove") }}
                                </b-button>
                          </b-col>
                     </b-row>
                 </b-container>

            </template>
         </b-table>
          <div v-if="items.length ==0">
            <center>
                <h2>{{ $t("registerationManage.noRegisterations") }}</h2>
            </center>
        </div> -->

    </div>
</template>

<script>
export default {
    data(){
        return{
            loading:false,
            deleteReqDialog:false,
            approveReqDialog: false,
            isProcessing: false,
            selectedItem:null,
            snackbar:false,
            alertMsg:'',
            search:'',
            fields: [
                // {value: 'id', text: this.$t("registerationManage.id") },
                {value: 'legalName', text: this.$t("registerationManage.legalName"),width:150 },
                {value: 'commercialName', text: this.$t("registerationManage.commercialName"),width:150 },
                {value: 'legalStructure', text: this.$t("registerationManage.legalStructure"),width:'150' },
                {value: 'yearOfEstablishment', text: this.$t("registerationManage.yearOfEstablishment"),width:200 },
                {value: 'webSite', text: this.$t("registerationManage.webSite"),width:'250' },
                {value: 'industryType', text: this.$t("registerationManage.industryType"),width:'150' },
                {value: 'businessSector', text: this.$t("registerationManage.businessSector") ,width:'150' },
                {value: 'businessSubSector', text: this.$t("registerationManage.businessSubSector") ,width:'200' },
                {value: 'contactPerson', text: this.$t("registerationManage.contactPerson") ,width:'200'},
                {value: 'contactPersonMail', text: this.$t("registerationManage.contactPersonMail") ,width:'200'},
                {value: 'contactPersonMobile', text: this.$t("registerationManage.contactPersonMobile"),width:'200' },
                {value: 'package', text: 'Subscription Package',width:'200' },
                {value: 'subscriptionPeriod', text: 'Period',width:'200' },
                {value: 'Action', text: this.$t("registerationManage.Action") },],

            fields2: [
                {key: 'id', label: this.$t("registerationManage.id") },
                {key: 'legalName', label: this.$t("registerationManage.legalName") },
                {key: 'commercialName', label: this.$t("registerationManage.commercialName") },
                {key: 'legalStructure', label: this.$t("registerationManage.legalStructure") },
                {key: 'yearOfEstablishment', label: this.$t("registerationManage.yearOfEstablishment") },
                {key: 'industryType', label: this.$t("registerationManage.industryType") },
                {key: 'businessSector', label: this.$t("registerationManage.businessSector") },
                {key: 'businessSubSector', label: this.$t("registerationManage.businessSubSector") },
                {key: 'contactPerson', label: this.$t("registerationManage.contactPerson") },
                {key: 'contactPersonMail', label: this.$t("registerationManage.contactPersonMail") },
                {key: 'contactPersonMobile', label: this.$t("registerationManage.contactPersonMobile") },
                {key: 'webSite', label: this.$t("registerationManage.webSite") },
                {key: 'Action', label: this.$t("registerationManage.Action") },],
            items: [],
        }
    },
    methods:{
        closeDelete(){
            this.deleteReqDialog = false;
        },
        closeApprove(){
            this.approveReqDialog = false;
        },
        removeRequest(item){
            this.selectedItem = item;
            this.deleteReqDialog = true;
        },
        deleteRequest(){
             this.$axios({
                    headers: {'Content-Type': 'application/json' },
                    method: 'delete',
                    url: `${this.$baseURL}/Account/CustomerRegisteration`,
                    params: {
                        Id:this.selectedItem.id
                    }
                })
                 .then((result)=>{
                     if(JSON.parse(result.data.status) == true){
                         this.items.splice(this.items.indexOf(this.selectedItem),1);
                         this.deleteReqDialog = false;
                         this.alertMsg = "Package updated successfully"
                         this.snackbar = true;
                     }
                 })

        },
        approveRequestConfirm(item){
            this.selectedItem = item;
            this.approveReqDialog = true;
        },
        approveRequest(){
            this.loading = true;
             this.$axios({
                    headers: {'Content-Type': 'application/json' },
                    method: 'post',
                    url: `${this.$baseURL}/Account/CustomerRegisterationApproval`,
                    params: {
                        Id:this.selectedItem.id
                    }
                })
                .then((result)=>{
                     if(JSON.parse(result.data.status) == true){
                         this.items.splice(this.items.indexOf(this.selectedItem),1);
                         this.approveReqDialog = false;
                         this.alertMsg = "Subscription created successfully"
                         this.snackbar = true;
                          this.loading = false;
                     }
                    this.approveReqDialog = false;
                    this.loading = false;

                })

        },
        loadRegisterations(){
            this.$axios({
                    headers: {'Content-Type': 'application/json' },
                    method: 'get',
                    url: `${this.$baseURL}/Account/CustomerRegisteration`,
                })
                .then((result)=>{
                    console.log("result......................")
                    console.log(result)
                     if(JSON.parse(result.data.status) == true){
                          this.items = result.data.registerations.map(p=>({
                             id:p.id,
                             legalName:p.legalName,
                             commercialName:p.commercialName,
                             legalStructure:p.legalStructure_en,
                             yearOfEstablishment:p.yearOfEstablishment,
                             webSite:p.website,
                             industryType:p.industryType_en,
                             businessSector:p.businessSector_en,
                             businessSubSector:p.BusinessSubSector_en,
                             contactPerson:p.contactPersonName,
                             contactPersonMail:p.contactPersonEMail,
                             contactPersonMobile:p.contactPersonTelNumber,
                             package:p.package,
                             subscriptionPeriod:this.getSubscriptionPeriod(p.subscriptionPeriod)

                         })
                         )


                     }
                });
        },
        getSubscriptionPeriod(period){
            switch(period){
                case 1:
                    return 'Quarter'
                case 2:
                    return 'Semi-Annual'
                case 3:
                    return 'Annual'
            }
        },
        RemoveRegisteration(item,index){
             this.$bvModal.msgBoxConfirm('Are you sure?')
              .then(value => {
                if(value == true){

                   this.$axios({
                    method: 'delete',
                    url: `${this.$baseURL}/Account/CustomerRegisteration`,
                    data: {Id:item.id}
                    }).then((result)=>{
                        if(JSON.parse(result.data.status) == true){
                            this.items.splice(index, 1);
                            this.alertMsg = "Package updated successfully"
                            this.snackbar = true;
                        }
                    });
                }
            });
        }
    },
    mounted(){
        this.loadRegisterations();
    }
}
</script>

<style>

</style>